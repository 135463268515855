<template>
  <Popup ref="popupRef" title="购买搜索" width="540px" :showConfirm="false" :showCancel="false">
    <div v-if="info">
      <div class="label">
        <span>你选择的任务：</span>
        <div class="seat-price">位置价格：<span class="red-text">{{info.price}}元/天/个</span> </div>
      </div>
      <CurrentTask :info="currentTask"></CurrentTask>
      <div class="label mt24">支付方式：</div>
      <Payway></Payway>
      <div class="label mt24">购买须知：</div>
      <div class="rule" style="white-space: pre-line;" v-if="info">{{info.rule}}</div>
      <div class="bottom-row">
        <div class="total">
          <div>合计 <span class="total-num">{{info.price}}</span>元/天/个 </div>
          <!-- <div class="tips">点击确认支付，代表你同意 <span class="policy">《自助购买协议》</span> </div> -->
        </div>
        <div class="btn-group">
          <div class="btn-cancel btn-border" @click="close()">取消</div>
          <div class="btn-confirm btn-fill-theme" @click="confirm()">确认支付</div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import CurrentTask from '@/components/CurrentTask'
import Payway from '@/components/Payway'
export default {
  components: {
    Popup,
    CurrentTask,
    Payway
  },
  data() {
    return {}
  },
  props: {
    currentTask: {
      type: Object,
      default: () => null
    },
    info: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    open(info) {
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    confirm() {
      this.$emit('confirm')
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
  .warn {
    color: var(--theme-color-red);
    font-size: 12px;
  }
}
.rule {
  color: #B0B3BF;
  font-size: 14px;
  line-height: 24px;
}
.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-cancel, .btn-confirm {
      padding: 10px 24px;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .total {
    font-size: 14px;
    color: var(--theme-color-red);
    .total-num {
      font-size: 24px;
      font-weight: bold;
    }
    .tips {
      font-size: 12px;
      color: #B0B3BF;
      margin-top: 8px;
    }
    .policy {
      color: var(--theme-color);
      cursor: pointer;
    }
  }
}
</style>