<template>
  <div class="task-list" :style="{'width': width}" v-if="taskList && taskList.length>0">
    <div style="display: inline-block; margin-right: 10px;" v-for="(item, index) in taskList" :key="index">
      <TaskItem :info="item" @choose="handleChoose"></TaskItem>
    </div>
    <!-- <div style="display: inline-block;" v-for="(item, index) in 10" :key="index">
      <TaskItem></TaskItem>
    </div> -->
  </div>
</template>

<script>
import TaskItem from '@/components/TaskItem'
export default {
  name: 'TaskListChoose',
  components: {
    TaskItem
  },
  data(){
    return {
      taskList: []
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  watch: {
    list(newList) {
      newList.forEach(item => {
        this.$set(item, 'choose', false)
      })
      this.taskList = newList
    }
  },
  created() {
    this.taskList = this.list
    this.taskList.forEach(item => {
      this.$set(item, 'choose', false)
    });
  },
  methods: {
    handleChoose(info) {
      this.taskList.forEach(item => {
        if(info.taskId == item.taskId) {
          item.choose = true
        } else {
          item.choose = false
        }
      })
      this.$emit('choose', {detail: info})
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 8px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.task-list {
  display: flex;
  align-items: center;
  background-color: #F6F6F6;
  border-radius: 8px;
  padding: 6px 6px 0;
  overflow-x: scroll;
  width: 100%;
}

</style>