<template>
  <div>
    <ContentLayout>
      <template #left>
        <div class="panel" v-if="searchSeatDetail">
          <div class="flex between-center mb16">
            <PanelTitle title="搜索词购买" :title-class="['fs20']"></PanelTitle>
            <RulePopover :rule="searchSeatDetail.rule"/>
          </div>
          <div class="flex">
            <img class="img-example" src="@/assets/images/example-search.png">
            <div class="right-content">
              <div class="flex between-center mb8">
                <PanelTitle :title="`搜索词位置(共${searchSeatDetail.seatNum}个)`"></PanelTitle>
                <div class="fs14">
                  <span>以下位置已被购买(剩余：<span class="red-text">{{searchSeatDetail.enableNum}}</span> 个)</span>
                  <!-- <span class="red-text">5个</span> -->
                </div>
              </div>
              <div class="search-list" v-if="soldSeatList && soldSeatList.length>0">
                <div class="search-row" v-for="(item, index) in soldSeatList" :key="index">
                  <div class="flex items-center">
                    <img v-if="index < 3" class="img-hot" :src="require(`@/assets/images/hot-${index+1}.png`)">
                    <div v-else class="tag-sort">{{index+1}}</div>
                    <div>
                      <div class="ptitle">{{item.taskTitle}}</div>
                      <div class="pname">{{item.taskProject}}</div>
                    </div>
                  </div>
                  <div class="right" v-if="item.status == 0">
                    <div class="down-title">释放倒计时</div>
                    <CountDown class="countdown" :time="item.releaseCountDown * 1000"></CountDown>
                  </div>
                  <div class="right" v-if="item.status == 1">
                    <div class="down-title">结束倒计时</div>
                    <u-count-down :time="item.endCountDown * 1000" autoStart></u-count-down>
                  </div>
                </div>
              </div>
              <div v-else class="empty-row">
                <Empty tips="热搜位暂时还空缺哦"/>
              </div>
              <div class="flex between-center mb8 mt8">
                <PanelTitle title="请选择目标任务" :textClass="['fs14']"></PanelTitle>
                <div v-if="currentTask" class="btn-rechoose" @click="openTotalTask">
                  <span>重新选择</span>
                  <img src="@/assets/images/arrow-right-theme.png">
                </div>
              </div>
              <!-- 选择任务 -->
              <CurrentTask :info="currentTask" @click.native="openTotalTask"></CurrentTask>
              <div class="btn-purchase btn-fill-theme" @click="toPurchase">立即购买 72元/天/个</div>
            </div>
          </div>
        </div>
      </template>
      <template #right>
        <PromoteSection></PromoteSection>
        <Asset class="mt16"></Asset>
      </template>
    </ContentLayout>
    <!-- 全部任务弹窗 -->
    <TaskListTotalPopup ref="totalPopupRef" :list="pubTaskList"
    @confirm="chooseTask" @loadmore="getPubTaskList($event.page)"></TaskListTotalPopup>
    <!-- 购买弹窗 -->
    <PurchasePopup ref="purchasePopupRef" :info="searchSeatDetail" :currentTask="currentTask" @confirm="handlePurchase"></PurchasePopup>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import PromoteSection from '@/components/PromoteSection'
import Asset from '@/components/Asset'
import ContentLayout from '@/components/ContentLayout'
import CountDown from '@/components/CountDown'
import TaskListChoose from '@/components/TaskListChoose'
import CurrentTask from '@/components/CurrentTask'
import TaskListTotalPopup from '@/components/TaskListTotalPopup'
import RulePopover from '@/components/RulePopover'
import Empty from '@/components/Empty'
import PurchasePopup from './components/PurchasePopup.vue'
import { pubTasksApi } from '@/api/task.js'
import { buySearchSeatApi, searchSeatsInfoApi } from '@/api/merchant.js'
export default {
  components: {
    PanelTitle,
    PromoteSection,
    Asset,
    ContentLayout,
    CountDown,
    TaskListTotalPopup,
    TaskListChoose,
    CurrentTask,
    Empty,
    PurchasePopup,
    RulePopover
  },
  data() {
    return {
      pubTaskList: [],
      searchSeatDetail: null,
      soldSeatList: [], // 已售出
      currentTask: null,
    }
  },
  created() {
    this.getPubTaskList()
    this.getSearchSeatDetail()
  },
  methods: {
    openTotalTask() {
      this.$refs.totalPopupRef.open()
    },
    chooseTask(e) {
      this.taskId = e.detail.taskId
      this.currentTask = e.detail
    },
    getPubTaskList(pageNo = 1) {
      pubTasksApi({
        startPage: pageNo-1 < 0 ? 0 : pageNo-1,
        type: 0, // 0 已上架
      }).then((res) => {
        console.log("个人任务列表", res);
        const list = res.data.taskListItems || []
        if(pageNo == 1) {
          this.pubTaskList = list
        } else {
          this.pubTaskList = this.pubTaskList.concat(list)
          if(list.length == 0) this.$tips({message: '没有更多数据了', type: 'warning'})
        }
      }).catch((err) => {
        this.$tips({ message: err, type: "error" });
      });
    },
    getSearchSeatDetail() {
      searchSeatsInfoApi().then(res => {
        console.log('搜索详情', res)
        this.searchSeatDetail = res.data
        this.soldSeatList = res.data.soldSeats || []
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      })
    },
    toPurchase() {
      if(!this.taskId) return this.$tips({ message: '请选择一个任务', type: "warning" });
      this.$refs.purchasePopupRef.open()
    },
    handlePurchase() {
      buySearchSeatApi({
        'price': this.searchSeatDetail.price,
				'taskId': this.taskId
      }).then(res => {
        console.log('购买搜索结果', res)
        this.$tips({ message: res.msg, type: "success" })
        this.getSearchSeatDetail()
        this.$store.dispatch('GET_USER_INFO') // 更新余额
      }).catch(err => {
        this.$tips({ message: err, type: "error" })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.img-example {
  width: 432px;
}
.right-content {
  margin-left: 24px;
  flex: 1;
  
}
.empty-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 342px;
  background-image: url('~@/assets/images/bg-search.png');
	background-size: contain;
	background-repeat: no-repeat;
	border-radius: 8px;
  border: 1px solid #F0F0F0;
}
.search-list {
	background-image: url('~@/assets/images/bg-search.png');
	background-size: contain;
	background-repeat: no-repeat;
	border-radius: 8px;
  border: 1px solid #F0F0F0;
	overflow: hidden;
	padding: 6px 16px;
  height: 342px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 32px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 32px;
  }
  
	.search-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 0;
		.img-hot {
			width: 18px;
			height: 18px;
			margin-right: 8px;
		}
		.tag-sort {
			width: 18px;
			height: 18px;
			line-height: 18px;
			border-radius: 3px;
			background-color: #CCCCCC;
			color: #fff;
			font-weight: bold;
			text-align: center;
			margin-right: 8px;
		}
		.ptitle {
			font-size: 14px;
      font-weight: bold;
			max-width: 200px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.pname {
			font-size: 12px;
			color: #B0B3BF;
			margin-top: 8px;
		}
		.right {
			text-align: right;
		}
		.down-title {
			font-size: 12px;
			margin-bottom: 4px;
			color: #65666F;
		}
    .countdown {
      font-size: 16px;
      font-weight: bold;
      color: var(--theme-color-red);
    }
	}
}

.btn-rechoose {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--theme-color);
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
}

.btn-purchase {
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}
</style>